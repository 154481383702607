<template>
  <div
    class="transcript-container"
    :class="{ 'transcript-container--reduced-bottom-margin': isMeeting }"
    v-show="isUserSpeechEnabled"
  >
    <div
      class="transcript"
      v-for="(transcript, index) in transcriptions"
      :key="index"
      ref="transcript"
    >
      <span class="username">{{ transcript.firstname }}: </span>
      {{ transcript.transcription }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import hark from "hark"
import * as moment from "moment"
import io from "socket.io-client"
import { Role } from "@/helpers"
import { ActionTypes as TwilioModuleActionTypes } from "@/store/TwilioModule"
import Mode from "@shared/enums/Mode"

const LIVE_SPEECH_SERVER = "polar-castle-25086.herokuapp.com/speech-to-text"
const SILENCE_TIME = 0.5 // 0.5 sec
const MAXIMUM_RECORDING_TIME = 5 * 1000 // 5 sec
const MAX_TRANSCRIPTIONS_LENGTH = 128
const TRANSCRIPTIONS_OFFSET = 64

export default {
  name: "Speech",
  data() {
    return {
      mediaRecorder: null,
      speaking: null,
      silenceTime: null,
      intervalID: null,
      recordingTime: null,
      recordingTimeIntervalId: null,
      transcriptions: [],
      localAudioTrack: null,
      stream: null
    }
  },
  computed: {
    ...mapGetters(["game", "user", "getCurrentMode", "onlineUsersArray"]),
    isUserSpeechEnabled() {
      return this.user?.speechToText || this.user?.speechToText !== false
        ? true
        : false
    },
    isUserMuted() {
      const currentUser = this.$store.state.auth.user
      return currentUser?.muted ?? false
    },
    canPlayerRecord() {
      return [Role.Player, Role.Host].includes(this.user?.role)
    },
    isMeeting() {
      return Mode.Meeting === this.getCurrentMode
    },
    isPickTeamPage() {
      return this.$route.name === "pickteams"
    }
  },
  async created() {
    if (this.canPlayerRecord) {
      const AudioContext = window.AudioContext || window.webkitAudioContext
      const audioContext = new AudioContext({ sampleRate: 44100 })

      if (!this.localAudioTrack) {
        this.localAudioTrack = await this[
          TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
        ]()
      }

      // disable track if user is muted
      this.localAudioTrack.mediaStreamTrack.enabled = !this.isUserMuted

      this.stream = new MediaStream([this.localAudioTrack.mediaStreamTrack])

      this.speech = hark(this.stream, {
        interval: 20,
        audioContext
      })
      this.speech.on("speaking", () => {
        console.log("Speaking!")
        this.speaking = true
        this.silenceTime = null
        clearInterval(this.intervalID)
      })

      this.speech.on("stopped_speaking", () => {
        if (!this.silenceTime) {
          this.silenceTime = moment().unix()
        }

        console.log("Stopped speaking...")
        this.intervalID = setInterval(() => {
          console.log(
            "You are silence",
            moment().unix() - this.silenceTime,
            " of ",
            SILENCE_TIME
          )
          if (
            this.speaking === true &&
            this.silenceTime &&
            moment().unix() - this.silenceTime >= SILENCE_TIME
          ) {
            this.speaking = false
            clearInterval(this.intervalID)
          }
        }, 500)
      })
    }

    this.startRecording()
  },
  watch: {
    speaking(val) {
      if (val) {
        this.mediaRecorder.start()
        this.countRecordingTime()
      } else {
        this.mediaRecorder.stop()
        this.stopRecordingTime()
      }
    },
    recordingTime(time) {
      if (time >= MAXIMUM_RECORDING_TIME && this.speaking) {
        this.mediaRecorder.stop()
        this.mediaRecorder.start()
        this.countRecordingTime()
      }
    },
    isUserSpeechEnabled(value) {
      if (!value) this.socket?.off("transcript", this.onTranscript)
      else this.socket?.on("transcript", this.onTranscript)
    },
    isUserMuted(val) {
      this.localAudioTrack.mediaStreamTrack.enabled = !val
    }
  },
  beforeDestroy() {
    try {
      this.socket.close()
    } catch (e) {}
    try {
      this.speech.stop()
    } catch (e) {}
    try {
      this.stopRecording()
    } catch (e) {}
    try {
      this.localAudioTrack.stop()
    } catch (e) {}
    this.socket?.off()
    clearInterval(this.intervalID)
  },
  updated() {
    if (this.isUserSpeechEnabled) {
      try {
        this.$el.scrollTop = this.$el.lastElementChild.offsetTop
      } catch (e) {}
    }
  },
  methods: {
    ...mapActions("twilio", [TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK]),
    startRecording() {
      const { id } = this.user
      const language = this.game?.speechLanguage ?? "en-US"
      this.socket = io(LIVE_SPEECH_SERVER, {
        path: "/speech",
        query: `roomID=${this.game.id}&userID=${id}&language=${language}`
      })

      if (this.canPlayerRecord) {
        this.mediaRecorder = new MediaRecorder(this.stream)

        this.socket.on("connect", () => {
          console.log("we are connected")
          this.mediaRecorder.ondataavailable = async event => {
            this.socket.binary(true).emit("data", {
              data: event.data,
              language: this.game?.speechLanguage ?? "en-US",
              context: this.$route.name
            })
          }
          this.mediaRecorder.onerror = () => {
            this.socket.close()
          }
        })
      }

      this.socket.on("disconnect", () => {
        console.log("speech to text disconncted")
      })

      if (this.isUserSpeechEnabled) {
        this.socket.on("transcript", this.onTranscript)
      }
    },
    onTranscript(data) {
      console.log("got transcript data", data)
      if (data && data.transcription) {
        this.mapTranscription(data)
      }
    },
    stopRecording() {
      this.mediaRecorder &&
        this.mediaRecorder.state !== "inactive" &&
        this.mediaRecorder.stop()

      this.socket && this.socket.off()
      this.mediaRecorder = null
    },
    countRecordingTime() {
      clearInterval(this.recordingTimeIntervalId)
      this.recordingTime = 0
      this.recordingTimeIntervalId = setInterval(() => {
        this.recordingTime += 500
      }, 500)
    },
    stopRecordingTime() {
      clearInterval(this.recordingTimeIntervalId)
    },
    mapTranscription(data) {
      const getUserPayload = userID =>
        this.onlineUsersArray.find(({ id }) => id === userID)

      const userPayload = getUserPayload(data.userID)

      const canSeeCaptions = this.$route.name === data.context

      if (this.isPickTeamPage && canSeeCaptions) {
        this.addToTranscriptions({ ...data, ...userPayload })
      } else if (canSeeCaptions) {
        if (this.getCurrentMode === "social") {
          if (userPayload?.selected) {
            this.addToTranscriptions({ ...data, ...userPayload })
          }
        } else if (this.isMeeting) {
          this.addToTranscriptions({ ...data, ...userPayload })
        } else {
          if (
            userPayload?.teamID === this.user.teamID ||
            userPayload?.id === this.user.id ||
            userPayload?.role === Role.Host
          ) {
            this.addToTranscriptions({ ...data, ...userPayload })
          }
        }
      }
    },
    addToTranscriptions(value) {
      if (this.transcriptions.length > MAX_TRANSCRIPTIONS_LENGTH) {
        this.transcriptions = [
          ...this.transcriptions.slice(TRANSCRIPTIONS_OFFSET),
          value
        ]
      } else {
        this.transcriptions.push(value)
      }
    }
  }
}
</script>

<style lang="scss">
.transcript-container {
  max-width: 35%;
  min-width: 35%;
  width: fit-content;
  min-height: 40px;
  background: $color-primary-dark;
  border: solid 2px $primary_accent_color;
  border-radius: 10px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 5px;
  padding: 5px;
  z-index: 444;
  max-height: 70px;
  overflow: auto;
  margin-top: 0px;
  margin-bottom: -50px;
  &--reduced-bottom-margin {
    margin-bottom: -10px;
  }
  .transcript {
    position: relative;
    color: white;
    font-size: 13px;
    letter-spacing: 0.1em;
    font-weight: bolder;

    line-height: 13px;
    .username {
      color: yellow;
      font-weight: bolder;
    }
  }
  .actions {
    margin-bottom: -9px;
  }
}
</style>
